import { pipe } from 'ramda';
import { connect } from 'react-redux';

import { ISuggestionsDispatchProps, ISuggestionsStateProps, Suggestions, TSuggestionsProps } from './index';
import { openChat, openChatAfterAuth } from '../../../actions/chat';
import { openNotAvailablePopup } from '../../../actions/notAvailablePopup';
import { TAgentRating } from '../../../api/agent';
import { IQsToUris } from '../../../api/offers';
import { Dispatch, IAppState } from '../../../common/state';
import { changeFavorite } from '../../../filters/state/favorite';
import { IOffer } from '../../../offer';
import { selectOffersPerPage } from '../../../selectors/experiments/selectOffersPerPage';
import { selectServicesBannerPositions } from '../../../selectors/selectServicesBannerPositions';
import { IApplicationContext } from '../../../types/applicationContext';
import { withApplicationContext } from '../../../utils/applicationContext';
import { callAuthenticationPopup } from '../../../utils/authentication';
import { prepareTrackingData } from '../../../utils/prepareTrackingData';
import { isGoogleBot, isYandexBot } from '../../../utils/user_agent';
import { hideOfferAction, IHidingOfferInfo } from '../../state/hide_offer';
import { hideMotivationPopup } from '../../state/login_motivation_popup';
import { updateAgentRating } from '../../state/offer_card/agent';
import { changeOfferComments } from '../../state/offer_card/comments';
import { addOfferComplaint } from '../../state/offer_card/complaints';
import {
  EFeedbackComplaint,
  IComplaintFeedbackBody,
  sendFeedbackComplaint,
  statusChanger,
} from '../../state/offer_card/feedback_complaint';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from '../../state/offer_card/simplified_card_popups';
import {
  addOfferToComparison,
  addOfferToComparisonAfterAuth,
  deleteOfferFromComparison,
} from '../../state/offersComparison';
import {
  ICoordinatesOffers,
  requestSuggestionsDistances,
  setQsToUris,
  setSuggestionDistancesSeoText,
} from '../../state/suggestions';

export const mapStateToProps = (state: IAppState): ISuggestionsStateProps => {
  const {
    aggregatedOffers: aggregatedOffersCount,
    extendedOffersCount,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    jsonQuery,
    offers,
    queryString,
    qsToUris,
    ratingBlockedAgents,
    totalOffers,
  } = state.results;

  const { pathname: urlPath } = new URL(state.results.fullUrl);

  const {
    currentPath,
    loginMotivationPopupOnFavorite,
    simplifiedOfferCardPopupsState,
    userAgent,
    hideOffer,
    complaintFeedbackFrom,
    maxAuctionBet,
    maxAuctionService,
    deviceType,
    soprApi,
    excludedUtilitiesTermsRegions,
    featureToggle,
    user,
    currentSubdomain,
    comparison,
  } = state;

  /* istanbul ignore next */
  const isPopupsOpened = () => {
    const popupsStateKeys = Object.keys(simplifiedOfferCardPopupsState).map(Number);

    return popupsStateKeys.some(offerId => Boolean(simplifiedOfferCardPopupsState[offerId].length));
  };

  const offersPerPage = selectOffersPerPage(state);

  return {
    aggregatedOffersCount,
    extendedOffersCount,
    offersQty: totalOffers,
    breadcrumbs: state.breadcrumbs,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    currentPageNumber: jsonQuery.page ? jsonQuery.page.value : 1,
    currentPath,
    isBot: isGoogleBot(userAgent) || isYandexBot(userAgent),
    // Является ли текущая выдача ЧПУ
    isPersonToPersonURL: Boolean(urlPath && !urlPath.startsWith('/cat.php')),
    isPrintEnabled: state.print.enabled,
    isPopupsOpened,
    jsonQuery,
    logger: state.logger,
    makeRequest: state.makeRequest,
    offers,
    offersPerPage,
    queryString,
    maxAuctionBet,
    maxAuctionService,
    qsToUris,
    ratingBlockedAgents,
    searchOffersApiBaseUrl: state.searchOffersApiBaseUrl,
    shownId: loginMotivationPopupOnFavorite.shownId,
    suggestions: state.results.suggestions,
    suggestionsDistances: state.suggestionsDistances,
    suggestionDistancesSeoText: state.suggestionDistancesSeoText,
    suggestionsQuery: state.results.suggestionsQuery,
    user: state.user,
    hideOffer,
    // лимит избранного для неавторизованного юзера
    favoritesLimitForUnauthUser: 5,
    directionsModal: state.filters.directionsModal,
    mlRankingGuid: state.mlRankingGuid,
    mlRankingModelVersion: state.mlRankingModelVersion,
    complaintsFormStatus: complaintFeedbackFrom.status,
    soprApi,
    deviceType,
    excludedUtilitiesTermsRegions,
    featureToggle,
    trackingData: prepareTrackingData(state.filters, state.results, state.breadcrumbs, user),
    subdomain: currentSubdomain,
    servicesBannerPositions: selectServicesBannerPositions(state),
    suburbanBuildersProjects: [],
    comparison,
  };
};

type TSuggestionsDispatchProps = ISuggestionsDispatchProps & { dispatch: Dispatch };

/* istanbul ignore next */
export const mapDispatchToProps = (dispatch: Dispatch): TSuggestionsDispatchProps => {
  return {
    onFavoriteChanged: (offer: IOffer, isFavorite: boolean) => {
      dispatch(changeFavorite(offer, isFavorite));
    },
    requestSuggestionsDistances: (coordinatesOffers?: ICoordinatesOffers[]) => {
      dispatch(requestSuggestionsDistances(coordinatesOffers));
    },
    closePopup: () => {
      dispatch(hideMotivationPopup());
    },
    onAgentRatingChanged: (offer: IOffer, rating: TAgentRating) => {
      dispatch(updateAgentRating(offer, rating));
    },
    onComplaintSent: (offerId: number, name: string, message?: string) => {
      dispatch(addOfferComplaint(offerId, name, message));
    },
    onOfferCommentsChanged: (offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined) => {
      dispatch(changeOfferComments(offer, commentOffer, commentAgent));
    },
    onPopupMoreOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'more'));
    },
    onPopupReportOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'report'));
    },
    onPopupMoreClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'more'));
    },
    onPopupReportClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'report'));
    },
    onUserInfoPopupOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'agent'));
    },
    onUserInfoPopupClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'agent'));
    },
    setQsToUris: (qsToUris: IQsToUris) => {
      dispatch(setQsToUris(qsToUris));
    },
    setSuggestionDistancesSeoText: (text: string) => {
      dispatch(setSuggestionDistancesSeoText(text));
    },
    hideOfferAction: (hidingOffer: IHidingOfferInfo) => {
      dispatch(hideOfferAction(hidingOffer));
    },
    sendComplaintFeedback: (body: IComplaintFeedbackBody) => {
      dispatch(sendFeedbackComplaint(body));
    },
    statusChanger: (status: EFeedbackComplaint) => {
      dispatch(statusChanger(status));
    },
    addToComparison: ({ offerId, category }) => {
      dispatch(addOfferToComparison({ offerId, category }));
    },
    addToComparisonAfterAuth: ({ offerId, category }) => {
      dispatch(addOfferToComparisonAfterAuth({ offerId, category }));
    },
    deleteFromComparison: ({ offerId }) => {
      dispatch(deleteOfferFromComparison({ offerId }));
    },
    dispatch,
  };
};

export const mergeProps = (
  stateProps: ISuggestionsStateProps,
  { dispatch, ...restDispatchProps }: TSuggestionsDispatchProps,
): TSuggestionsProps => {
  const { user } = stateProps;

  return {
    ...stateProps,
    openChat: (offer: IOffer, position: number) => {
      if (!user.isAuthenticated) {
        dispatch(openChatAfterAuth({ offer, position }));
        callAuthenticationPopup();

        return;
      }

      dispatch(openChat({ offer, position }));
    },
    openNotAvailablePopup: (offer: IOffer, position: number) => dispatch(openNotAvailablePopup({ offer, position })),
    ...restDispatchProps,
  };
};

function mapContextToProps(context: IApplicationContext): IApplicationContext {
  return context;
}

export const SuggestionsContainer = pipe(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withApplicationContext(mapContextToProps),
)(Suggestions);
