import { connect } from 'react-redux';

import { IOffersDispatchProps, IOffersStateProps, Offers, TOffersProps } from './index';
import { openChat, openChatAfterAuth } from '../../../actions/chat';
import { openNotAvailablePopup } from '../../../actions/notAvailablePopup';
import { TAgentRating } from '../../../api/agent';
import { Dispatch, IAppState } from '../../../common/state';
import { changeFavorite } from '../../../filters/state/favorite';
import { IKpOffersByCategoryCounter, IOffer } from '../../../offer';
import { selectOffersPerPage } from '../../../selectors/experiments/selectOffersPerPage';
import { selectIsSubsidisedMortgageZeroResultsBannerAvailable } from '../../../selectors/selectIsSubsidisedMortgageZeroResultsBannerAvailable';
import { selectServicesBannerPositions } from '../../../selectors/selectServicesBannerPositions';
import { callAuthenticationPopup } from '../../../utils/authentication';
import { dealTypeFromJsonQuery, FDealType, isSuburban, offerTypeFromJsonQuery } from '../../../utils/category';
import { prepareTrackingData } from '../../../utils/prepareTrackingData';
import { isGoogleBot, isYandexBot } from '../../../utils/user_agent';
import { hideOfferAction, IHidingOfferInfo } from '../../state/hide_offer';
import { hideMotivationPopup } from '../../state/login_motivation_popup';
import { updateAgentRating } from '../../state/offer_card/agent';
import { changeOfferComments } from '../../state/offer_card/comments';
import { addOfferComplaint } from '../../state/offer_card/complaints';
import {
  EFeedbackComplaint,
  IComplaintFeedbackBody,
  sendFeedbackComplaint,
  statusChanger,
} from '../../state/offer_card/feedback_complaint';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from '../../state/offer_card/simplified_card_popups';
import {
  addOfferToComparison,
  addOfferToComparisonAfterAuth,
  deleteOfferFromComparison,
} from '../../state/offersComparison';

/* istanbul ignore next */
export const mapStateToProps = (state: IAppState): IOffersStateProps => {
  const {
    currentPath,
    logger,
    loginMotivationPopupOnFavorite,
    print,
    results,
    simplifiedOfferCardPopupsState,
    suggestionDistancesSeoText,
    userAgent,
    user,
    hideOffer,
    complaintFeedbackFrom,
    maxAuctionBet,
    maxAuctionService,
    villagesLink,
    isCountrysideTrapEnabled,
    excludedUtilitiesTermsRegions,
    featureToggle,
    filters,
    breadcrumbs,
    auctionBanks,
    abUseExperiments,
    villagePromotionLabel,
    isCountrysideBuildersBannerAvailable,
    suburbanBuildersProjects,
    comparison,
  } = state;

  const {
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    jsonQuery,
    kp,
    offers,
    suggestions,
    qsToUris,
    ratingBlockedAgents,
    aggregatedOffers,
    extendedOffersCount,
  } = results;

  const emptyKpOffersCounter = (kp && kp.offers_by_category_counter) || {};
  const hasKpEmptyResults =
    Object.keys(emptyKpOffersCounter).filter((key: keyof IKpOffersByCategoryCounter) =>
      Number(emptyKpOffersCounter[key]),
    ).length > 0;
  const isSuburbanListing = isSuburban(offerTypeFromJsonQuery(jsonQuery));

  const showCountrysideTrap = isCountrysideTrapEnabled && isSuburbanListing;
  const isPopupsOpened = () => {
    const popupsStateKeys = Object.keys(simplifiedOfferCardPopupsState).map(Number);

    return popupsStateKeys.some(offerId => Boolean(simplifiedOfferCardPopupsState[offerId].length));
  };
  const offersPerPage = selectOffersPerPage(state);

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const showSuburbanListingBanner = isSuburbanListing && [FDealType.Sale, FDealType.RentLongterm].includes(dealType);

  return {
    queryString: results.queryString,
    offersQty: results.totalOffers,
    breadcrumbs,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    currentPageNumber: jsonQuery.page ? jsonQuery.page.value : 1,
    currentPath,
    isBot: isGoogleBot(userAgent) || isYandexBot(userAgent),
    isPopupsOpened,
    isPrintEnabled: print.enabled,
    jsonQuery,
    logger,
    offers,
    suggestions: suggestions || [],
    hideOffer,
    offersPerPage,
    qsToUris,
    ratingBlockedAgents,
    showEmptyKpSearch: hasKpEmptyResults,
    shownId: loginMotivationPopupOnFavorite.shownId,
    suggestionDistancesSeoText,
    user,
    maxAuctionBet,
    maxAuctionService,
    mlRankingGuid: state.mlRankingGuid,
    mlRankingModelVersion: state.mlRankingModelVersion,

    // лимит избранного для неавторизованного юзера
    favoritesLimitForUnauthUser: 5,

    complaintsFormStatus: complaintFeedbackFrom.status,
    villagesLink,
    showCountrysideTrap,

    // количество всех и расширенных офферов, используем для вычисления позиции в выдаче
    aggregatedOffersCount: aggregatedOffers,
    extendedOffersCount,
    excludedUtilitiesTermsRegions,
    featureToggle,
    trackingData: prepareTrackingData(filters, results, breadcrumbs, user),
    auctionBanks,
    abUseExperiments,
    villagePromotionLabel,
    isCountrysideBuildersBannerAvailable,
    isSubsidisedMortgageZeroResultsBannerAvailable: selectIsSubsidisedMortgageZeroResultsBannerAvailable(state),
    servicesBannerPositions: selectServicesBannerPositions(state),
    showSuburbanListingBanner,
    suburbanBuildersProjects,
    comparison,
  };
};

type TOffersDispatchProps = IOffersDispatchProps & { dispatch: Dispatch };

export const mapDispatchToProps = (dispatch: Dispatch): TOffersDispatchProps => {
  return {
    closePopup: () => {
      dispatch(hideMotivationPopup());
    },
    onAgentRatingChanged: (offer: IOffer, rating: TAgentRating) => {
      dispatch(updateAgentRating(offer, rating));
    },
    onComplaintSent: (offerId: number, name: string, message?: string) => {
      dispatch(addOfferComplaint(offerId, name, message));
    },
    onFavoriteChanged: (offer: IOffer, isFavorite: boolean) => {
      dispatch(changeFavorite(offer, isFavorite));
    },
    onOfferCommentsChanged: (offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined) => {
      dispatch(changeOfferComments(offer, commentOffer, commentAgent));
    },
    onPopupMoreOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'more'));
    },
    onPopupReportOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'report'));
    },
    onPopupMoreClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'more'));
    },
    onPopupReportClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'report'));
    },
    onUserInfoPopupOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'agent'));
    },
    onUserInfoPopupClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'agent'));
    },
    hideOfferAction: (hidingOffer: IHidingOfferInfo) => {
      dispatch(hideOfferAction(hidingOffer));
    },
    sendComplaintFeedback: (body: IComplaintFeedbackBody) => {
      dispatch(sendFeedbackComplaint(body));
    },
    statusChanger: (status: EFeedbackComplaint) => {
      dispatch(statusChanger(status));
    },
    addToComparison: ({ offerId, category }) => {
      dispatch(addOfferToComparison({ offerId, category }));
    },
    addToComparisonAfterAuth: ({ offerId, category }) => {
      dispatch(addOfferToComparisonAfterAuth({ offerId, category }));
    },
    deleteFromComparison: ({ offerId }) => {
      dispatch(deleteOfferFromComparison({ offerId }));
    },
    dispatch,
  };
};

export const mergeProps = (
  stateProps: IOffersStateProps,
  { dispatch, ...restDispatchProps }: TOffersDispatchProps,
): TOffersProps => {
  const { user } = stateProps;

  return {
    ...stateProps,
    openChat: (offer: IOffer, position: number) => {
      if (!user.isAuthenticated) {
        dispatch(openChatAfterAuth({ offer, position }));
        callAuthenticationPopup();

        return;
      }

      dispatch(openChat({ offer, position }));
    },
    openNotAvailablePopup: (offer: IOffer, position: number) => dispatch(openNotAvailablePopup({ offer, position })),
    ...restDispatchProps,
  };
};

export const OffersContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Offers);
