import { IConfig } from '@cian/config/shared';

import { DAILY_RENT_OTA_CONVERSION } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

interface IProps {
  config: IConfig;
  experiments: IAbUseExperiment[];
}

export function isDailyRentOtaConversionExperimentEnabled(context: IProps) {
  const experiment = context.experiments.find(exp => exp.experimentName === DAILY_RENT_OTA_CONVERSION);

  if (!experiment) {
    return false;
  }

  const isParticipateInExperiment = Boolean(
    context.config.get<boolean>('Dailyrent.WithoutInstantBookingFilterExperiment.Enabled'),
  );
  const isCorrectExperimentGroup = experiment.groupName === 'B';

  return isParticipateInExperiment && isCorrectExperimentGroup;
}
