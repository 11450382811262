import { Avatar, Icon3DTour16, Image, UIHeading2, UIHeading3, UIHeading4, UIText1, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { INewbuildingLayoutWithOffers } from '../../../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

interface IModalHeaderProps {
  contactsButton: React.ReactNode;
  layout: INewbuildingLayoutWithOffers;
}

export const ModalHeader = ({ contactsButton, layout }: IModalHeaderProps) => {
  return (
    <div className={style['container']}>
      <div className={style['image']}>
        <Image src={layout.imageUrl || ''} alt="Планировка" width={240} height={188} objectFit="contain" />
        {layout.tourUrl && (
          <div className={style['tour']}>
            <Icon3DTour16 color="icon-main-default" />
          </div>
        )}
      </div>
      <div className={style['info-block']}>
        <div className={style['layout']}>
          <div className={style['layout-params']}>
            <UIText1>{layout.roomType}</UIText1>
            <UIHeading3>{layout.areaRangeText}</UIHeading3>
          </div>
          <div className={style['layout-price']}>
            <UIHeading2 textAlign="right">{layout.priceRangeText}</UIHeading2>
            <UIText1 color="current_color" textAlign="right">
              {layout.pricePerMeterRangeText}
            </UIText1>
          </div>
        </div>
        <div className={style['contacts']}>
          {layout.builder && (
            <div className={style['builder']} data-testid="OffersModalBuilder">
              <Avatar size={40} type="builder" src={layout.builder?.imgUrl} />
              <div className={style['builder-description']}>
                <UIText3 color="gray60_100">Застройщик</UIText3>
                <UIHeading4>{layout.builder?.name}</UIHeading4>
              </div>
            </div>
          )}
          <div className={style['contacts-control']} data-testid="OffersModalContactButton">
            {contactsButton}
          </div>
        </div>
      </div>
    </div>
  );
};
