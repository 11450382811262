import { INewbuildingPromoBuilderOffers } from '../../../../types/newbuildingPromoBuilderOffers';
import { IJsonQuery } from '../../../common/json_query';
import { IPuidsSchema } from '../../../search-offers/entities/entities/PuidsSchema';
import { IYMEventsSchema } from '../../../search-offers/entities/ym_events/YMEventsSchema';
import { IOffer, EOfferExtensionType } from '../types';
import { INewbuildingSchema } from '../types/NewbuildingSchema';
import { INewbuildingSimilarOffersBlockSchema } from '../types/NewbuildingSimilarOffersBlockSchema';

export interface IAvgPriceInformer {
  price: number;
  currency: 'rur' | 'eur' | 'usd';
}

export interface IBreadcrumb {
  url: string | null;
  title: string | null;
}

export interface ISerpDataQueristringToUri {
  [qs: string]: string;
}

export interface IQuickLink {
  url: string;
  offersCount: number | null;
  title: string;
}

export interface IQuickLinks {
  level: number;
  links: IQuickLink[] | null;
}

export interface ISerpDataRedirectInstructions {
  location: string | null;
  statusCode: number | null;
}

export interface ISearchSeoData {
  keywords: string | null;
  text: string | null;
  h1: string | null;
  description: string | null;
  title: string | null;
}

export interface ISeoSchemas {
  wideSelection: string;
  mortgageDiscount: string;
  onlineShow: string;
  organizationInfo: string;
  product: string;
  breadcrumbs: string;
}

export interface IMarkedListItem {
  /** Блок текстов **/
  content: string;
  /** Отображать блок в развернутом виде **/
  expanded: boolean;
  /** Заголовок блока текстов **/
  title: string;
}

export interface IMarkedList {
  schema: string | null;
  items: IMarkedListItem[];
}

export interface ILink {
  url: string;
  title: string;
}

export interface IFastLink {
  uri: string;
  title: string;
}

export enum EMaxAuctionService {
  top = 'top3',
  premium = 'premium',
  paid = 'paid',
}

/**
 * @property key - ключ коллекции объявлений для фонового изображения
 */
export interface ICollectionItem {
  key: string;
  title: string;
  url: string;
  label: string;
  queryString: string;
  backgroundColor: string | null;
  textColor: string | null;
}

export interface ISearchOffersDesktopResponseDataSchema {
  aggregatedCount: number;
  /** количество расширенных офферов в выдаче */
  extendedOffersCount: number;
  avgPriceInformer: IAvgPriceInformer | null;
  maxAuctionBet: number;
  maxAuctionService?: EMaxAuctionService;
  breadcrumbs: IBreadcrumb[];
  fullUrl: string;
  jsonQuery: IJsonQuery;
  lastModified: string | null;
  offerCount: number;
  offersSerialized: IOffer[];
  qsToUris: ISerpDataQueristringToUri | null;
  queryString: string;
  quickLinks: IQuickLinks | null;
  redirectData: ISerpDataRedirectInstructions | null;
  seoData: ISearchSeoData;
  seoLinks: ILink[] | null;
  seoSchemas: ISeoSchemas | null;
  markedList: IMarkedList | null;
  suggestOffersSerializedList: IOffer[] | null;
  suggestionsQuery: string | null;
  topHitsLinks: IFastLink[] | null;
  mlRankingGuid: string | null;
  newbuildingSimilarOffers: string | null;
  newbuildingAdPath: string | null;
  /** Информация для блока похожих новостроечных объявлений **/
  newbuildingSimilarOffersBlock?: INewbuildingSimilarOffersBlockSchema | null;
  mlRankingModelVersion: string | null;
  mlRankingResponseCached: boolean | null;
  // id запроса. На каждый запрос приходит новый id
  searchRequestId: string;
  kp: IKpOffers | null;
  hasExtendedOffers: boolean;
  /** типы расширенных офферов попавших в выдачу */
  extensionTypes: EOfferExtensionType[];
  /** промо подборки карточек-фильтров  */
  collections: ICollectionItem[] | null;
  newbuildingIdsForBuildersPromoSlider: number[];
  newbuildingPromoBuilderOffers: INewbuildingPromoBuilderOffers | null;
  newbuilding?: INewbuildingSchema | null;
  puids?: IPuidsSchema | null;
  /** События для Яндекс.Метрики **/
  ymEvents?: IYMEventsSchema | null;
}

export interface IKpOffers {
  offers_by_category_counter: IKpOffersByCategoryCounter;
}

export interface IKpOffersByCategoryCounter {
  cottageSale?: number;
  townhouseSale?: number;
  houseSale?: number;
  houseShareSale?: number;
  landSale?: number;
}
