import { IConfig } from '@cian/config/shared';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectRegionId } from '../../../../../../../shared/selectors/filters/selectRegionId';
import { FOfferType, offerTypeFromJsonQuery, TJsonQuery } from '../../../../../../JsonQuery';
import {
  SUBSIDIZED_MORTGAGE_ENABLED_KEY,
  SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY,
  SUBSIDIZED_MORTGAGE_LABEL_TEXT_IN_MIXED_LISTING_KEY,
} from '../../../../constants/config';
import { SUBSIDIZED_MORTGAGE_OPTION_VALUE } from '../constants';

interface IParams {
  jsonQuery: TJsonQuery;
  config: IConfig;
}

export const useSubsidizedMortgageOption = ({ jsonQuery, config }: IParams) => {
  const regionId = useSelector(selectRegionId);

  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const subsidizedMortgageEnabled = config.get<boolean>(SUBSIDIZED_MORTGAGE_ENABLED_KEY);
  const subsidizedMortgageLabelText = config.get<string>(SUBSIDIZED_MORTGAGE_LABEL_TEXT_IN_MIXED_LISTING_KEY) ?? '';
  const subsidizedMortgageExcludedRegions = config.get<number[]>(SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY) ?? [];

  const isSubsidizedMortgageOptionAvailable =
    subsidizedMortgageEnabled &&
    offerType !== FOfferType.FlatNew &&
    subsidizedMortgageLabelText &&
    !subsidizedMortgageExcludedRegions.includes(regionId);

  return useMemo(() => {
    if (isSubsidizedMortgageOptionAvailable) {
      return {
        label: subsidizedMortgageLabelText,
        value: SUBSIDIZED_MORTGAGE_OPTION_VALUE,
      };
    }

    return undefined;
  }, [isSubsidizedMortgageOptionAvailable, subsidizedMortgageLabelText]);
};
