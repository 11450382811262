import { addSavedOffer } from '@cian/favorites-assign-widget';

import { requestAuthentication } from '../../actions/authentication';
import { IAppState, TActions, Dispatch } from '../../common/state';
import { IOffer } from '../../offer';
import { showMotivationPopup } from '../../serp/state/login_motivation_popup';
import { TThunkAction } from '../../types/redux';
import { selectIsAuthorizationTrapExperimentEnabled } from '../../utils/experiments/selectIsAuthorizationTrapExperimentEnabled';
import { changeFavorite as apiChangeFavorite, IFavoriteChangeResponse } from '../api';

export const FavoriteChangedActionType = 'filters/favorite/FAVORITE_CHANGED';
export const FavoriteChangedErrorActionType = 'filters/favorite/FAVORITE_CHANGED_ERROR';

export interface IFavoriteChangedAction {
  type: 'filters/favorite/FAVORITE_CHANGED';
  offerId: number;
}

export interface IFavoriteChangedErrorAction {
  type: 'filters/favorite/FAVORITE_CHANGED_ERROR';
  offerId: number;
}

export const onAddToFavoriteSuccessAuth =
  (offer: IOffer, offerId: number, isFavorite: boolean, currentCount: number) =>
  (): TThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({
      type: FavoriteChangedActionType,
      offerId,
    });

    dispatch(onChangeFavorite(offer, isFavorite, currentCount));
  };

export const onAddToFavoriteCloseNoAuth = (offerId: number) => (): TThunkAction => (dispatch: Dispatch) => {
  dispatch({
    type: FavoriteChangedErrorActionType,
    offerId,
  });
};

export function changeFavorite(offer: IOffer, isFavorite: boolean) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { user, loginMotivationPopupOnFavorite, abUseExperiments } = getState();
    const offerId = offer.cianId || offer.id;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentCount = (window as any).__header_favorites_count__ || 0;
    // Эксперимент, ограничение на добавление в избранное для неавторизованных
    const isAuthorizationTrapExperimentEnabled = selectIsAuthorizationTrapExperimentEnabled(abUseExperiments);

    if (isFavorite && !user.isAuthenticated && currentCount >= 0 && isAuthorizationTrapExperimentEnabled) {
      dispatch(
        requestAuthentication({
          source: 'favorites',
          viewData: { headingType: 'addFavorite' },
          onSuccess: onAddToFavoriteSuccessAuth(offer, offerId, isFavorite, currentCount),
        }),
      );
    } else {
      dispatch({
        type: FavoriteChangedActionType,
        offerId,
      });

      if (isFavorite) {
        if (!user.isAuthenticated && loginMotivationPopupOnFavorite.isActive) {
          dispatch(showMotivationPopup(offerId));
        }
      }
      dispatch(onChangeFavorite(offer, isFavorite, currentCount));
    }
  };
}

export function onChangeFavorite(offer: IOffer, isFavorite: boolean, currentCount: number) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { makeRequest } = getState();
    const offerId = offer.cianId || offer.id;

    apiChangeFavorite(makeRequest, offer, isFavorite).then(
      (data: IFavoriteChangeResponse) => {
        if (isFavorite) {
          const userHasFolders = Boolean(data.folderInfo && data.folderInfo.hasFolders);
          const folder = data.folderInfo?.folder;
          let savedInFolders = null;
          if (folder?.name && folder?.id) {
            savedInFolders = [
              {
                id: folder.id,
                name: folder.name,
              },
            ];
          }

          dispatch(addSavedOffer({ offerIds: [offer.id], savedInFolders, userHasFolders }));

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__header_favorites_count__ = currentCount + 1;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__header_favorites_count__ = currentCount - 1;
        }

        if (data.newbuilding_id !== undefined && window.__header_updateFavorites__) {
          window.__header_updateFavorites__(true);
        }

        return window.__header_updateFavorites__ && window.__header_updateFavorites__(isFavorite);
      },
      () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__header_favorites_count__ = currentCount - 1;
        changeFavoriteError(offerId);
      },
    );
  };
}

export function changeFavoriteError(offerId: number): IFavoriteChangedErrorAction {
  return {
    type: FavoriteChangedErrorActionType,
    offerId,
  };
}

const getChangedState = (state: IAppState, action: IFavoriteChangedAction | IFavoriteChangedErrorAction): IAppState => {
  return {
    ...state,
    results: {
      ...state.results,
      offers: state.results.offers.map(offer => {
        return offer.cianId === action.offerId || offer.id === action.offerId
          ? Object.assign({}, offer, {
              isFavorite: !offer.isFavorite,
            })
          : offer;
      }),
    },
  };
};

export function favoriteReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case FavoriteChangedActionType:
      return getChangedState(state, action);
    case FavoriteChangedErrorActionType:
      return getChangedState(state, action);
  }

  return state;
}
