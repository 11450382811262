import { IAppStore } from './store';
import { advancedApplyReducer } from '../../filters/state/advanced/apply';
import { areaReducer } from '../../filters/state/advanced/area';
import { balconiesReducer } from '../../filters/state/advanced/balconies';
import { bboxReducer } from '../../filters/state/advanced/bbox';
import { bedroomsTotalReducer } from '../../filters/state/advanced/bedrooms_total';
import { ceilingHeightReducer } from '../../filters/state/advanced/ceiling_height';
import { comissionReducer } from '../../filters/state/advanced/comission';
import { decorationTypeReducer } from '../../filters/state/advanced/decoration';
import { developerTypeReducer } from '../../filters/state/advanced/developer';
import { doneYearReducer } from '../../filters/state/advanced/done_year';
import { dwellingStatusReducer } from '../../filters/state/advanced/dwelling_status';
import { electronicTradingTypeReducer } from '../../filters/state/advanced/electronic_trading_type';
import { elevatorsReducer } from '../../filters/state/advanced/elevators';
import { facilitiesReducer } from '../../filters/state/advanced/facilities';
import { flatShareReducer } from '../../filters/state/advanced/flat_share';
import { floorReducer } from '../../filters/state/advanced/floor';
import { floorsTotalReducer } from '../../filters/state/advanced/floors_total';
import { fromMKADReducer } from '../../filters/state/advanced/from_mkad';
import { garageKindReducer } from '../../filters/state/advanced/garage_kind';
import { garageMaterialReducer } from '../../filters/state/advanced/garage_material';
import { garageStatusReducer } from '../../filters/state/advanced/garage_status';
import { garageTypeReducer } from '../../filters/state/advanced/garage_type';
import { heatingReducer } from '../../filters/state/advanced/heating';
import { houseMaterialReducer } from '../../filters/state/advanced/house_material';
import { houseYearReducer } from '../../filters/state/advanced/house_year';
import { landStatusReducer } from '../../filters/state/advanced/land_status';
import { layoutTypeReducer } from '../../filters/state/advanced/layout';
import { offerIdReducer } from '../../filters/state/advanced/offer_id';
import { offerSellerReducer } from '../../filters/state/advanced/offer_seller';
import { parkingReducer } from '../../filters/state/advanced/parking';
import { phoneReducer } from '../../filters/state/advanced/phone';
import { publishPeriodReducer } from '../../filters/state/advanced/publish_period';
import { rentTermsReducer } from '../../filters/state/advanced/rent_terms';
import { rentTimeReducer } from '../../filters/state/advanced/rent_time';
import { repairReducer } from '../../filters/state/advanced/repair';
import { roomsInDealReducer } from '../../filters/state/advanced/rooms_in_deal';
import { roomsTotalReducer } from '../../filters/state/advanced/rooms_total';
import { saleTypeReducer } from '../../filters/state/advanced/sale_type';
import { specialOffersReducer } from '../../filters/state/advanced/specialOffers';
import { suburbanHouseTypeReducer } from '../../filters/state/advanced/suburban_house_type';
import { suburbanUtilitiesReducer } from '../../filters/state/advanced/suburban_utilities';
import { suburbanWCReducer } from '../../filters/state/advanced/suburban_wc';
import { tenantsReducer } from '../../filters/state/advanced/tenants';
import { advancedTermReducer } from '../../filters/state/advanced/term';
import { toggleAdvancedModalReducer } from '../../filters/state/advanced/toggle_modal';
import { undergroundTimeReducer } from '../../filters/state/advanced/underground_time';
import { wcReducer } from '../../filters/state/advanced/wc';
import { windowsTypeReducer } from '../../filters/state/advanced/windows';
import { wordsExcludeReducer } from '../../filters/state/advanced/words_exclude';
import { wordsIncludeReducer } from '../../filters/state/advanced/words_include';
import { builderReducer } from '../../filters/state/builder';
import { currencyTypeReducer } from '../../filters/state/currency_type';
import { dealTypeReducer } from '../../filters/state/deal_type';
import { directionsModalReducer } from '../../filters/state/directions';
import { toggleDistrictsModalReducer } from '../../filters/state/districts/popup';
import { saveDistrictsModalReducer } from '../../filters/state/districts/popup_save';
import { domrfReducer } from '../../filters/state/domrf';
import { favoriteReducer } from '../../filters/state/favorite';
import { geoSuggestReducer } from '../../filters/state/geo';
import { hasFlatTourBookingReducer } from '../../filters/state/has_flat_tour_booking';
import { hasProfitbaseBookingReducer } from '../../filters/state/has_profitbase_booking';
import { hiddenBaseSearchReducer } from '../../filters/state/hidden_base_search';
import { identicalOffersIdReducer } from '../../filters/state/identical_offers_id';
import { changeJsonQueryReducer } from '../../filters/state/json_query';
import { kpIdReducer } from '../../filters/state/kp_id';
import {
  locationSwitcherAutocompleteReducer,
  locationSwitcherPopupReducer,
  locationSwitcherReducer,
} from '../../filters/state/location_switcher';
import { deleteAreaReducer } from '../../filters/state/map/area_delete';
import { changeMapReducer } from '../../filters/state/map/map_changed';
import { saveMapModalReducer } from '../../filters/state/map/save_modal';
import { toggleMapModalReducer } from '../../filters/state/map/toggle_modal';
import { metaReducer } from '../../filters/state/meta';
import { metroSelectionReducer } from '../../filters/state/metro/changed';
import { saveMetroModalReducer } from '../../filters/state/metro/save_modal';
import { toggleMetroModalReducer } from '../../filters/state/metro/toggle_modal';
import { multiIdReducer } from '../../filters/state/multi_id';
import { newbuildingHouseReducer } from '../../filters/state/newbuilding_house';
import { offerTypeReducer } from '../../filters/state/offer_type';
import { paginationReducer } from '../../filters/state/pagination';
import { priceReducer } from '../../filters/state/price';
import { priceTypeReducer } from '../../filters/state/price_type';
import { promoSearchTypesReducer } from '../../filters/state/promoSearchTypes';
import { regionalDistrictsModalReducer } from '../../filters/state/regionalDistricts';
import { roomReducer } from '../../filters/state/room';
import { searchReducer } from '../../filters/state/search';
import { sortReducer } from '../../filters/state/sort';
import { suburbanOfferFilterReducer } from '../../filters/state/suburban_offer_filter';
import { tagsReducer } from '../../filters/state/tags';
import { fitTagsInOneLineReducer } from '../../filters/state/tags/fit_tags_in_one_line';
import { tour3dReducer } from '../../filters/state/tour_3d';
import { undergroundTravelReducer } from '../../filters/state/underground_travel';
import { userIdReducer } from '../../filters/state/user_id';
import { villageMortgageFilterReducer } from '../../filters/state/village_mortgage_filter';
import { withLayoutReducer } from '../../filters/state/with_layout';
import { withNeighborsReducer } from '../../filters/state/with_neighbors';
import { withPhotoReducer } from '../../filters/state/with_photo';
import { chatReducer } from '../../reducers/chat';
import { complainReducer } from '../../reducers/complain';
import { favoritesAssignReducer } from '../../reducers/favoritesAssign';
import { journalHighlightsReducer } from '../../reducers/journalHighlights';
import { mortgageDataReducer } from '../../reducers/mortgageData';
import { newbuildingInformationReducer } from '../../reducers/newbuildingInformation/reducer';
import { newbuildingLayoutOffersReducer } from '../../reducers/newbuildingLayoutOffers';
import { newbuildingLayoutsCountReducer } from '../../reducers/newbuildingLayoutsCount';
import { newbuildingLayoutsOnReducer } from '../../reducers/newbuildingLayoutsOn';
import { notAvailablePopupReducer } from '../../reducers/notAvailablePopup';
import { userReducer } from '../../reducers/user';
import { flatTourBookingReducer } from '../../serp/state/flatTourBooking';
import { hideOfferReducer } from '../../serp/state/hide_offer';
import { infoForTgbReducer } from '../../serp/state/infoForTgb/reducer/infoForTgbReducer';
import { showMotivationPopupReducer } from '../../serp/state/login_motivation_popup';
import { newbuildingConsultant } from '../../serp/state/newbuildingConsultant';
import { newbuildingInfoReducer } from '../../serp/state/newbuilding_info';
import { agentRatingReducer } from '../../serp/state/offer_card/agent';
import { offerCommentsReducer } from '../../serp/state/offer_card/comments';
import { offerComplaintsReducer } from '../../serp/state/offer_card/complaints';
import { complaintFeedbackReducer } from '../../serp/state/offer_card/feedback_complaint';
import { simplifiedOfferCardPopupsStateReducer } from '../../serp/state/offer_card/simplified_card_popups';
import { comparisonNotificationReducer, offerComparisonStatusReducer } from '../../serp/state/offersComparison';
import { offersPrintReducer } from '../../serp/state/print';
import { suggestionsDistancesReducer } from '../../serp/state/suggestions';

export function attachReducers(store: IAppStore) {
  store.addReducer(advancedApplyReducer);
  store.addReducer(advancedTermReducer);
  store.addReducer(agentRatingReducer);
  store.addReducer(areaReducer);
  store.addReducer(balconiesReducer);
  store.addReducer(bboxReducer);
  store.addReducer(newbuildingHouseReducer);
  store.addReducer(ceilingHeightReducer);
  store.addReducer(changeMapReducer);
  store.addReducer(comissionReducer);
  store.addReducer(currencyTypeReducer);
  store.addReducer(dealTypeReducer);
  store.addReducer(decorationTypeReducer);
  store.addReducer(deleteAreaReducer);
  store.addReducer(developerTypeReducer);
  store.addReducer(directionsModalReducer);
  store.addReducer(domrfReducer);
  store.addReducer(doneYearReducer);
  store.addReducer(dwellingStatusReducer);
  store.addReducer(elevatorsReducer);
  store.addReducer(facilitiesReducer);
  store.addReducer(flatShareReducer);
  store.addReducer(favoriteReducer);
  store.addReducer(fitTagsInOneLineReducer);
  store.addReducer(floorReducer);
  store.addReducer(floorsTotalReducer);
  store.addReducer(fromMKADReducer);
  store.addReducer(garageKindReducer);
  store.addReducer(garageMaterialReducer);
  store.addReducer(garageStatusReducer);
  store.addReducer(garageTypeReducer);
  store.addReducer(geoSuggestReducer);
  store.addReducer(hasFlatTourBookingReducer);
  store.addReducer(hasProfitbaseBookingReducer);
  store.addReducer(heatingReducer);
  store.addReducer(hiddenBaseSearchReducer);
  store.addReducer(houseMaterialReducer);
  store.addReducer(houseYearReducer);
  store.addReducer(suburbanOfferFilterReducer);
  store.addReducer(kpIdReducer);
  store.addReducer(landStatusReducer);
  store.addReducer(layoutTypeReducer);
  store.addReducer(locationSwitcherAutocompleteReducer);
  store.addReducer(locationSwitcherPopupReducer);
  store.addReducer(locationSwitcherReducer);
  store.addReducer(metaReducer);
  store.addReducer(metroSelectionReducer);
  store.addReducer(multiIdReducer);
  store.addReducer(newbuildingInfoReducer);
  store.addReducer(offerCommentsReducer);
  store.addReducer(offerComplaintsReducer);
  store.addReducer(offerIdReducer);
  store.addReducer(specialOffersReducer);
  store.addReducer(offerTypeReducer);
  store.addReducer(offersPrintReducer);
  store.addReducer(paginationReducer);
  store.addReducer(parkingReducer);
  store.addReducer(phoneReducer);
  store.addReducer(priceReducer);
  store.addReducer(priceTypeReducer);
  store.addReducer(publishPeriodReducer);
  store.addReducer(rentTermsReducer);
  store.addReducer(rentTimeReducer);
  store.addReducer(repairReducer);
  store.addReducer(roomReducer);
  store.addReducer(roomsInDealReducer);
  store.addReducer(roomsTotalReducer);
  store.addReducer(saleTypeReducer);
  store.addReducer(saveDistrictsModalReducer);
  store.addReducer(saveMapModalReducer);
  store.addReducer(saveMetroModalReducer);
  store.addReducer(searchReducer);
  store.addReducer(showMotivationPopupReducer);
  store.addReducer(simplifiedOfferCardPopupsStateReducer);
  store.addReducer(infoForTgbReducer);
  store.addReducer(sortReducer);
  store.addReducer(suburbanUtilitiesReducer);
  store.addReducer(suburbanWCReducer);
  store.addReducer(suburbanHouseTypeReducer);
  store.addReducer(suggestionsDistancesReducer);
  store.addReducer(hideOfferReducer);
  store.addReducer(tenantsReducer);
  store.addReducer(toggleAdvancedModalReducer);
  store.addReducer(toggleDistrictsModalReducer);
  store.addReducer(toggleMapModalReducer);
  store.addReducer(toggleMetroModalReducer);
  store.addReducer(undergroundTravelReducer);
  store.addReducer(userIdReducer);
  store.addReducer(wcReducer);
  store.addReducer(windowsTypeReducer);
  store.addReducer(withPhotoReducer);
  store.addReducer(tour3dReducer);
  store.addReducer(withNeighborsReducer);
  store.addReducer(withLayoutReducer);
  store.addReducer(wordsExcludeReducer);
  store.addReducer(wordsIncludeReducer);
  store.addReducer(complaintFeedbackReducer);
  store.addReducer(identicalOffersIdReducer);
  store.addReducer(newbuildingConsultant);
  store.addReducer(changeJsonQueryReducer);
  store.addReducer(favoritesAssignReducer);
  store.addReducer(mortgageDataReducer);
  store.addReducer(chatReducer);
  store.addReducer(userReducer);
  store.addReducer(offerComparisonStatusReducer);
  store.addReducer(comparisonNotificationReducer);
  store.addReducer(flatTourBookingReducer);
  store.addReducer(newbuildingInformationReducer);
  store.addReducer(newbuildingLayoutsCountReducer);
  store.addReducer(newbuildingLayoutOffersReducer);
  store.addReducer(newbuildingLayoutsOnReducer);
  store.addReducer(bedroomsTotalReducer);
  store.addReducer(villageMortgageFilterReducer);
  store.addReducer(notAvailablePopupReducer);
  store.addReducer(journalHighlightsReducer);
  store.addReducer(regionalDistrictsModalReducer);
  store.addReducer(complainReducer);
  store.addReducer(electronicTradingTypeReducer);
  store.addReducer(offerSellerReducer);
  store.addReducer(undergroundTimeReducer);
  store.addReducer(builderReducer);
  store.addReducer(promoSearchTypesReducer);
  /*
   * Алексей, Максим, другой Алексей, Слава, Илья или кто-либо другой, пожалуйста,
   * не трогайте этот Reducer, он всегда должен быть в конце ;(
   */
  store.addReducer(tagsReducer);
}
