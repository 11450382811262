import * as React from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-restricted-paths
import { removeCookie } from '../../../../browser/utils/js-cookie';
import { EBannerType } from '../../../constants/bannerType';
import { DiscountMortgageBannerContainer } from '../../../containers/NewbuildingDiscountMortgageBanner';
import { NewbuildingRentBannerContainer } from '../../../containers/NewbuildingRentBannerContainer';
import { getDealType } from '../../../selectors/getDealType';
import { getJsonQuery } from '../../../selectors/getJsonQuery';
import { getNewbuildingSimilarAdPath } from '../../../selectors/getNewbuildingSimilarAdPath';
import { getNewbuildingSimilarOffers } from '../../../selectors/getNewbuildingSimilarOffers';
import { getOfferType } from '../../../selectors/getOfferType';
import { selectIsNewbuildingConsultBannerAvailable } from '../../../selectors/newbuildingConsultBanner';
import { getIsNewbuildingBannerRotationEnabled } from '../../../selectors/newbuildingEntryBanner';
import { selectNewbuildingFlightBannerAvailable } from '../../../selectors/newbuildingFlight';
import { selectBannerTypeRotation } from '../../../selectors/selectBannerTypeRotation';
import { selectSuburbanBlackFridayBannerUrl } from '../../../selectors/suburbanBlackFriday';
import { EDealType } from '../../../types/dealType';
import { useApplicationContext } from '../../../utils/applicationContext';
import { FOfferType, isSuburban } from '../../../utils/category';
import { getNewbuildingEntryBannerInfo } from '../../../utils/getNewbuildingEntryBannerInfo';
import { NewbuildingConsultBannerContainer } from '../../components/NewbuildingConsultBanner';
import {
  NewbuildingEntryResaleBlock,
  trackNewbuildingEntryResaleBlockShow,
  trackNewbuildingEntryResaleBlockButtonClick,
  trackNewbuildingEntryResaleBlockItemClick,
} from '../../components/NewbuildingEntryResaleBlock';
import { NewbuildingFlightBannerContainer } from '../../components/NewbuildingFlightBanner';
import { SuburbanBlackFridayBannerContainerLoadable } from '../../components/SuburbanBlackFridayBanner';
import { SuburbanBuildersProjectsBanner } from '../../components/SuburbanBuildersProjectsBanner';
import { IIntegrateAdOptions } from '../../components/offers/helpers';
import { ObservableBannerServicePlace } from '../../components/service_places/banner/container';
import { CianDobroBannerContainer } from '../CianDobroBannerContainer';
import { DealSaleBannerContainer } from '../DealSaleBannerContainer';
import { MortgageBrokerBannerContainer } from '../MortgageBrokerBannerContainer';
import { SubsidisedMortgageBannerContainer } from '../SubsidisedMortgageBannerContainer';

export interface IBannerConatinerProps {
  options: IIntegrateAdOptions;
  position: number;
  isAgent?: boolean;
  locationId?: number;
  isCountrysideBuildersBannerAvailable?: boolean;
}

export function BannerContainer({
  options,
  position,
  isAgent,
  isCountrysideBuildersBannerAvailable,
}: IBannerConatinerProps) {
  const isNewbuildingBannerRotationEnabled = useSelector(getIsNewbuildingBannerRotationEnabled);
  const bannerTypeRotation = useSelector(selectBannerTypeRotation);
  const isNewbuildingConsultBannerAvailable = useSelector(selectIsNewbuildingConsultBannerAvailable);
  const isNewbuildingFlightBannerAvailable = useSelector(selectNewbuildingFlightBannerAvailable);
  const dealType = useSelector(getDealType);
  const offerType = useSelector(getOfferType);
  const jsonQuery = useSelector(getJsonQuery);
  const nbSimilarOffers = useSelector(getNewbuildingSimilarOffers);
  const nbSimilarAdPath = useSelector(getNewbuildingSimilarAdPath);
  const { logger, config } = useApplicationContext();
  const isMortgageBannerEnabled = config.get<boolean>('mortgage.isSerpBannerEnabled');
  const isDiscountMortgageBannerEnabled = config.get<boolean>('newbuildingDiscountMortgageBanner.Enabled');
  const suburbanBlackFridayUrl = useSelector(selectSuburbanBlackFridayBannerUrl);

  const rotationBanner = React.useMemo(() => {
    switch (bannerTypeRotation) {
      case EBannerType.DealSale:
        return <DealSaleBannerContainer />;
      case EBannerType.MortgageBroker:
        return <MortgageBrokerBannerContainer />;
      case EBannerType.SubsidisedMortgage:
        return <SubsidisedMortgageBannerContainer />;
      case EBannerType.CianDobro:
        return <CianDobroBannerContainer />;
      case EBannerType.RentKpnCallback:
      case EBannerType.RentNBListing:
        return <NewbuildingRentBannerContainer bannerType={bannerTypeRotation} />;
      default:
        return null;
    }
  }, [bannerTypeRotation]);

  React.useEffect(
    /* istanbul ignore next */ () => {
      if (!isCountrysideBuildersBannerAvailable) {
        removeCookie('buildersBannerShown');
      }
    },
    [isCountrysideBuildersBannerAvailable],
  );

  // Определяем, нужно ли показывать новостроечный баннер КпН в новостроечной и смешанной выдаче
  if (isNewbuildingBannerRotationEnabled && !isAgent && isNewbuildingConsultBannerAvailable) {
    if (isDiscountMortgageBannerEnabled) {
      return <DiscountMortgageBannerContainer />;
    }

    // Во время флайта заменяем баннер КпН на баннер флайта.
    if (isNewbuildingFlightBannerAvailable) {
      return <NewbuildingFlightBannerContainer />;
    }

    return <NewbuildingConsultBannerContainer />;
  }

  // Определяем, нужно ли показывать блоки объявлений из новостроек в выдаче квартир вторички
  if (
    isNewbuildingBannerRotationEnabled &&
    nbSimilarOffers &&
    nbSimilarAdPath &&
    offerType === FOfferType.FlatOld &&
    dealType === EDealType.Sale
  ) {
    const { title, description } = getNewbuildingEntryBannerInfo('resale', config, logger);

    return (
      <NewbuildingEntryResaleBlock
        onShow={trackNewbuildingEntryResaleBlockShow(jsonQuery)}
        onButtonClick={trackNewbuildingEntryResaleBlockButtonClick(jsonQuery)}
        onItemClick={trackNewbuildingEntryResaleBlockItemClick(jsonQuery)}
        url={nbSimilarAdPath}
        title={title}
        description={description}
        items={nbSimilarOffers}
      />
    );
  }

  // Если в загородке в продаже акция "Черная пятница" активна, то показываем баннер
  if (isSuburban(offerType) && dealType === EDealType.Sale && suburbanBlackFridayUrl) {
    return <SuburbanBlackFridayBannerContainerLoadable href={suburbanBlackFridayUrl} />;
  }

  if ((offerType & FOfferType.Land) !== 0 && dealType === EDealType.Sale) {
    return <SuburbanBuildersProjectsBanner />;
  }

  if (rotationBanner) {
    return rotationBanner;
  }

  // Если ипотека выключена, то ничего не отображаем
  if (!isMortgageBannerEnabled) {
    return null;
  }

  // В остальных случаях показываем банер ипотеки
  return <ObservableBannerServicePlace options={options} position={position} />;
}
