import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  trackNewbuildingLayoutDetailsOpen,
  trackNewbuildingLayoutOfferClick,
  trackNewbuildingLayoutOffersListingClick,
} from './tracking';
import { getNewbuildingLayoutOffers } from '../../actions/newbuildingLayoutOffers';
import { ErrorScreen } from '../../components/NewbuildingLayoutOffersModal/components/ErrorScreen';
import { ModalContent } from '../../components/NewbuildingLayoutOffersModal/components/ModalContent';
import { ModalHeader } from '../../components/NewbuildingLayoutOffersModal/components/ModalHeader';
import { OffersTable } from '../../components/NewbuildingLayoutOffersModal/components/OffersTable';
import { Skeleton } from '../../components/NewbuildingLayoutOffersModal/components/Skeleton';
import { residentialComplexLayoutOffersSelector } from '../../selectors/newbuildingLayouts';
import { residentialComplexLayoutOffersRequestStatusSelector } from '../../selectors/newbuildingLayouts/residentialComplexLayoutOffersRequestStatus';
import { ENewbuildingCallPlaceType } from '../../types/newbuildingHeader';
import { TThunkDispatch } from '../../types/redux';
import { ENewbuildingLayoutFetchStatus } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';
import { NewbuildingHeaderCallContainer } from '../NewbuildingHeaderCall';

export const NewbuildingLayoutDetails = ({ layoutKey }: { layoutKey: string }) => {
  const dispatch = useDispatch<TThunkDispatch>();
  const layout = useSelector(residentialComplexLayoutOffersSelector);
  const requestStatus = useSelector(residentialComplexLayoutOffersRequestStatusSelector);

  const onRetry = React.useCallback(() => {
    dispatch(getNewbuildingLayoutOffers({ layoutKey }));
  }, []);

  React.useEffect(() => {
    trackNewbuildingLayoutDetailsOpen();
  }, []);

  const onOfferLinkClick = React.useCallback(() => {
    trackNewbuildingLayoutOfferClick();
  }, []);

  const onOffersListingLinkClick = React.useCallback(() => {
    trackNewbuildingLayoutOffersListingClick();
  }, []);

  if (!layout && requestStatus === ENewbuildingLayoutFetchStatus.Loading) {
    return (
      <ModalContent>
        <Skeleton />
      </ModalContent>
    );
  }

  if (!layout && requestStatus === ENewbuildingLayoutFetchStatus.Error) {
    return (
      <ModalContent>
        <ErrorScreen onRetry={onRetry} />
      </ModalContent>
    );
  }

  if (!layout) {
    return null;
  }

  return (
    <ModalContent>
      <ModalHeader
        contactsButton={
          <NewbuildingHeaderCallContainer
            theme="fill_secondary"
            action="Open_layout_modal_block"
            placeType={ENewbuildingCallPlaceType.NewbuildingOpenLayoutModalBlock}
          />
        }
        layout={layout}
      />
      <OffersTable
        onOfferLinkClick={onOfferLinkClick}
        onOffersListingLinkClick={onOffersListingLinkClick}
        layout={layout}
      />
    </ModalContent>
  );
};
