import { IconButton, Spinner } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ChatPopup.css';
import { getAgentName } from './getAgentName';
import { IOffer } from '../../offer';

interface IChatPopupProps {
  offer: IOffer | null;
  onClose(): void;
  dates: { checkin: string; checkout: string } | null;
}

export const ChatPopup: React.FC<IChatPopupProps> = ({ offer, onClose, dates }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  let src: string;

  if (offer) {
    const { user, cianId, dealType, offerType, gaLabel } = offer;

    const companionName = getAgentName(offer);

    /* istanbul ignore next */
    src =
      `/dialogs/?hostType=frame&offerId=${cianId}` +
      `&dealType=${dealType}&offerType=${offerType}` +
      `${companionName ? '&companionName=' + encodeURIComponent(companionName) : ''}` +
      `${user?.cianUserId ? '&companionId=' + encodeURIComponent(user?.cianUserId) : ''}` +
      `${gaLabel ? '&gaLabel=' + encodeURIComponent(gaLabel) : ''}` +
      '&pageType=Listing' +
      `${dates ? '&checkin=' + encodeURIComponent(dates.checkin) : ''}` +
      `${dates ? '&checkout=' + encodeURIComponent(dates.checkout) : ''}`;
  } else {
    src = '/dialogs/?hostType=frame&chatbot=1&source=Listing&pageType=Listing';
  }

  return (
    <div className={styles['container']}>
      <span className={styles['close']}>
        <IconButton aria-label="Закрыть" icon={<IconActionClose16 />} onClick={onClose} />
      </span>
      {isLoading && (
        <span className={styles['spinner']} data-testid="spinner">
          <Spinner size={16} />
        </span>
      )}
      <iframe data-testid="ChatModal" className={styles['iframe']} src={src} onLoad={onLoad} onError={onLoad} />
    </div>
  );
};
