import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';

import { ResponseError } from '../../errors';
import { IJsonQuery } from '../../json_query';
import {
  fetchGetNewbuildingLayoutsCount,
  TGetNewbuildingLayoutsCountResponse,
} from '../../repositories/search-offers-index/v1/get-newbuilding-layouts-count';
import { IResidentialComplexLayoutsCountResponse } from '../../types/residentialComplexLayouts';

interface IFetchResidentialComplexLayoutsCountParameters {
  jsonQuery: IJsonQuery;
}

interface IContext {
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
}

async function fetchResidentialComplexLayoutsCount(
  { httpApi }: IContext,
  { jsonQuery }: IFetchResidentialComplexLayoutsCountParameters,
): Promise<IResidentialComplexLayoutsCountResponse> {
  const residentialComplexLayoutsCount: TGetNewbuildingLayoutsCountResponse = await fetchGetNewbuildingLayoutsCount({
    httpApi,
    parameters: {
      jsonQuery,
    },
  });

  if (residentialComplexLayoutsCount.statusCode === 400) {
    throw new ResponseError({
      domain: 'fetchGetNewbuildingLayoutsCount',
      message: residentialComplexLayoutsCount.response.message,
    });
  }

  return residentialComplexLayoutsCount.response;
}

export async function fetchResidentialComplexLayoutsCountSafe(
  context: IContext,
  parameters: IFetchResidentialComplexLayoutsCountParameters,
): Promise<IResidentialComplexLayoutsCountResponse> {
  try {
    return await fetchResidentialComplexLayoutsCount(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Информация о Планировках в ЖК' });

    return {
      offersCount: 0,
    };
  }
}
