import { getDealType } from '../../../../selectors/getDealType';
import { EDealType } from '../../../../types/dealType';
import { IAppState } from '../../../state';

export function getSeoTabsLinksAvailable(state: IAppState): boolean {
  const { config, seoTabsLinks } = state;

  const dealType = getDealType(state);
  const isDailyRentQuery = dealType === EDealType.RentDaily;

  const isToggleEnabled = Boolean(config.get<boolean>('FrontendSerp.SeoTabsLinks.Enabled'));
  const isSeoTabsLinksPresent = Boolean(seoTabsLinks?.length);

  return isToggleEnabled && isSeoTabsLinksPresent && isDailyRentQuery;
}
