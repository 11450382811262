/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetNewbuildingLayoutWithOffersDesktopModel,
  IGetNewbuildingLayoutWithOffersDesktopRequest,
  IMappers,
  IGetNewbuildingLayoutWithOffersDesktopResponse,
  IGetNewbuildingLayoutWithOffersDesktopResponseError,
  TGetNewbuildingLayoutWithOffersDesktopResponse,
} from './types';

const defaultConfig: TGetNewbuildingLayoutWithOffersDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers',
  pathApi: '/v1/get-newbuilding-layout-with-offers-desktop/',
  hostType: 'api',
} as TGetNewbuildingLayoutWithOffersDesktopModel;

function createGetNewbuildingLayoutWithOffersDesktopModel(
  parameters: IGetNewbuildingLayoutWithOffersDesktopRequest,
): TGetNewbuildingLayoutWithOffersDesktopModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingLayoutWithOffersDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetNewbuildingLayoutWithOffersDesktopRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetNewbuildingLayoutWithOffersDesktop<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetNewbuildingLayoutWithOffersDesktopOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetNewbuildingLayoutWithOffersDesktopResponse
> {
  const { response, statusCode } = await httpApi.fetch(
    createGetNewbuildingLayoutWithOffersDesktopModel(parameters),
    config,
  );

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingLayoutWithOffersDesktopResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetNewbuildingLayoutWithOffersDesktopResponseError);
    }
  }

  return { response, statusCode } as TGetNewbuildingLayoutWithOffersDesktopResponse;
}

export { defaultConfig, createGetNewbuildingLayoutWithOffersDesktopModel, fetchGetNewbuildingLayoutWithOffersDesktop };
