import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';

import { ResponseError } from '../../errors';
import { prepareNewbuildingLayouts } from '../../mappers/prepareNewbuildingLayouts';
import {
  fetchSearchNewbuildingLayoutsDesktop,
  TSearchNewbuildingLayoutsDesktopResponse,
} from '../../repositories/search-offers/v1/search-newbuilding-layouts-desktop';
import { INewbuildingLayoutsForDesktop } from '../../types/residentialComplexLayouts/layouts/residentialComplexLayouts';

type ISearchNewbuildingLayoutsDesktopParameters = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonQuery: any;
  subdomain: string;
  path: string;
};

type IContext = {
  httpApi: IHttpApi;
  logger: ILogger;
};

export async function fetchSearchNewbuildingLayouts(
  { httpApi }: IContext,
  _parameters: ISearchNewbuildingLayoutsDesktopParameters,
): Promise<INewbuildingLayoutsForDesktop> {
  const parameters = { jsonQuery: _parameters.jsonQuery, subdomain: _parameters.subdomain, path: _parameters.path };

  const offersData: TSearchNewbuildingLayoutsDesktopResponse = await fetchSearchNewbuildingLayoutsDesktop({
    httpApi,
    parameters,
  });

  if (offersData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchSearchNewbuildingLayoutsDesktop',
      message: offersData.response.message,
      details: {
        error: offersData.response.errors,
      },
    });
  }

  return prepareNewbuildingLayouts(offersData.response);
}
